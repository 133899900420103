<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tabla Dinamica Clientes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Comercialización</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Clientes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div class="card">
          <div class="card-header pt-2 pb-2">
            <div class="row">
              <div class="col-md-6">
                <h5>Filtros</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- Acordeón de filtros -->
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label>Seleccionar Tipo Busqueda</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="tipo_consulta"
                    :class="
                      $v.tipo_consulta.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option :value="1">Clientes</option>
                    <option :value="2">Documentos</option>
                    <option :value="3">Lista Distribución</option>
                  </select>
                </div>
              </div>

              <div class="form-group col-md-2">
                <label>Numero de identificación</label>
                <input
                  type="number"
                  placeholder="Numero de identificación"
                  label="id"
                  class="form-control form-control-sm p-0"
                  v-model="filtros.n_identificacion"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Razon Social</label>
                <input
                  placeholder="Razon Social"
                  label="razon_social"
                  class="form-control form-control-sm p-0"
                  type="text"
                  id="razon_social"
                  v-model="filtros.razon_social"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Linea de Negocio</label>
                <select
                  class="form-control form-control-sm p-0"
                  v-model="filtros.linea_negocio_id"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="linea_negocio_id in listasForms.lineas_negocio"
                    :key="linea_negocio_id.id"
                    :value="linea_negocio_id.id"
                  >
                    {{ linea_negocio_id.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Representante</label>
                <input
                  type="text"
                  id="representante"
                  placeholder="Representante"
                  label="representante"
                  v-model="filtros.representante"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Ciudad</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="ciudad"
                  placeholder="Ciudades"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.ciudades"
                  :filterable="true"
                  @input="getSelectCiudades()"
                ></v-select>
              </div>
              <div class="form-group col-md-2">
                <label>Email</label>
                <input
                  type="text"
                  id="email"
                  placeholder="Email"
                  label="email"
                  v-model="filtros.email"
                  class="form-control form-control-sm p-0"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Tipo operacion</label>
                <select
                  id="tipo_operacion"
                  class="form-control form-control-sm p-0"
                  v-model="filtros.tipo_operacion"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo in listasForms.tipo_operacion"
                    :key="tipo.numeracion"
                    :value="tipo.numeracion"
                  >
                    {{ tipo.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label>Estado</label>
                <select
                  id="tipo_operacion"
                  class="form-control form-control-sm p-0"
                  v-model="filtros.estado"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  v-show="!$v.tipo_consulta.$invalid"
                  class="btn bg-navy btn-sm"
                  @click="generarPivotTable()"
                  v-if="$store.getters.can('com.clientes.pivotTable')"
                >
                  Generar Busqueda
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <pivot-table-component
                      v-if="pivotData"
                      :pivotData="pivotData"
                      :aggregator-name="aggregatorName"
                      :renderer-name="rendererName"
                    >
                    </pivot-table-component>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i>
                  <br />Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PivotTableComponent from "../../../../components/PivotTableComponent.vue";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "ClientePivotTable",
  components: {
    PivotTableComponent,
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      dataPivot: [],
      pivotData: [],
      aggregatorName: "Sum",
      rendererName: "Table Heatmap",
      filtros: {
        n_identificacion: null,
        razon_social: null,
        linea_negocio_id: null,
        representante: null,
        ciudad_id: null,
        estado: null,
        email: null,        
      },
      ciudad: {},
      tipo_consulta: null,
      listasForms: {
        lineas_negocio: [],
        ciudades: [],        
        estados: [],
      },
    };
  },
  validations: {
    tipo_consulta: {
      required,
    },
  },
  methods: {
    back() {
      return this.$router.replace("/Com/Clientes");
    },

    async getLineasNegocio() {
      await axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    getSelectCiudades() {      
      this.filtros.ciudad_id = null;
      if (this.ciudad) {        
        this.filtros.ciudad_id = this.ciudad.id;
      }
    },

    async getCiudades() {
      await axios.get("/api/admin/ciudades/lista").then((response) => {
        this.listasForms.ciudades = response.data;
      });
    },   

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    generarPivotTable() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/com/clientes/pivotTable",
        data: { tipo_consulta: this.tipo_consulta, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          this.pivotData = response.data;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getLineasNegocio();
    await this.getCiudades();    
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
